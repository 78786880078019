export const trimSDS = (handle: string) => {
	if (handle.endsWith('-sds')) {
		return handle.slice(0, -4);
	}
	return handle;
};

export const addSDS = (handle: string) => {
	if (handle.endsWith('-sds')) {
		return handle;
	}
	return `${handle}-sds`;
};
