import artifact from '$houdini/artifacts/CartBuyerIdentityUpdate'
import { MutationStore } from '../runtime/stores/mutation'

export class CartBuyerIdentityUpdateStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
