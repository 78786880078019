import type { EligibleProductsResponse, SkuWithAmount } from '$lib/types/special-sale';
import { readonly, writable } from 'svelte/store';
import type { RequiredCardData$data } from '$houdini';
import { isNetworkError } from '$lib/utils';
import type { RequiredProduct } from '$lib/types';
import { browser } from '$app/environment';
import { mapToNode } from '$lib/utils/product';
import { trimSDS } from '$lib/utils/sds';

export const createSpecialSaleStore = (...items: (SkuWithAmount | undefined | null)[]) => {
	let retries = 0;

	function _executeFetch() {
		fetch('/api/special-sale', {
			body: JSON.stringify(saleData),
			headers: { 'Content-Type': 'application/json' },
			method: 'POST'
		})
			.then((response) => response.json())
			.then((data) => {
				response.set(data);
			})
			.catch((e) => {
				if (isNetworkError(e)) {
					if (retries < 3) {
						retries++;
						setTimeout(
							() => {
								_executeFetch();
							},
							2000 * (retries + 1)
						);
					}
				} else {
					throw e;
				}
			});
	}

	const response = writable<EligibleProductsResponse[]>([], () => {
		if (browser && saleData.length > 0) {
			_executeFetch();
		}
	});
	const saleData = items.filter(Boolean) as SkuWithAmount[];

	return readonly(response);
};

export const createSkuItemFromProduct = (product: RequiredProduct) => {
	return {
		amount: 1,
		collections: mapToNode(product.collections).map((collection) => collection.handle),
		handle: trimSDS(product.handle)
	};
};

export const createSkuItemsFromCart = (
	cartData: NonNullable<RequiredCardData$data['lines']['edges'][number]['node']>[] | undefined
) => {
	return (
		cartData?.map((line) => {
			return {
				amount: line.quantity,
				collections: mapToNode(line.merchandise.product.collections).map(
					(collection) => collection.handle
				),
				handle: line.merchandise.product.handle
			};
		}) ?? []
	);
};
