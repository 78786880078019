export default {
    "name": "GetSDSOnlyInfo",
    "kind": "HoudiniQuery",
    "hash": "842b2c61958023e0dce4be092ce41e3e5a1c8457ab53901b1bacd17da6ba0d37",

    "raw": `query GetSDSOnlyInfo($language: LanguageCode, $country: CountryCode, $handle: String, $sdsHandle: String) @inContext(language: $language, country: $country) {
  product(handle: $handle) {
    variants(first: 250) {
      edges {
        node {
          ...BaseSDSOnlyData
          id
        }
      }
    }
    id
  }
  sdsProduct: product(handle: $sdsHandle) {
    variants(first: 250) {
      edges {
        node {
          ...BaseSDSOnlyData
          id
        }
      }
    }
    id
  }
}

fragment BaseSDSOnlyData on ProductVariant {
  id
  sku: metafield(key: "just_sku", namespace: "sprenger") {
    key
    value
    id
  }
  sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
    key
    value
    id
  }
  quantityAvailable
  __typename
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "product": {
                "type": "Product",
                "keyRaw": "product(handle: $handle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 250)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "sds_qty": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sds_qty(key: \"sds_qty\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "BaseSDSOnlyData": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "sdsProduct": {
                "type": "Product",
                "keyRaw": "sdsProduct(handle: $sdsHandle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 250)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "sds_qty": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sds_qty(key: \"sds_qty\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "BaseSDSOnlyData": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "language": "LanguageCode",
            "country": "CountryCode",
            "handle": "String",
            "sdsHandle": "String"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=c1bf0928719827f6a0aa1b3cf11528af318200a59be096f258a4e02509e922d7";