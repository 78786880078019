import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetSDSOnlyInfo'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetSDSOnlyInfoStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetSDSOnlyInfoStore",
			variables: false,
		})
	}
}

export async function load_GetSDSOnlyInfo(params) {
	await initClient()

	const store = new GetSDSOnlyInfoStore()

	await store.fetch(params)

	return {
		GetSDSOnlyInfo: store,
	}
}
