import { derived, get, writable } from 'svelte/store';
import { browser } from '$app/environment';
import {
	type BaseSDSOnlyData$data,
	CartBuyerIdentityUpdateStore,
	CartCreateStore,
	CartDiscountCodesUpdateStore,
	CartLinesAddStore,
	CartLinesRemoveStore,
	CartLinesUpdateStore,
	CartStore,
	GetSDSOnlyInfoStore,
	type RequiredCardData$data
} from '$houdini';
import { customerSessionStore } from '$lib/store/customerSession.store';
import { languageStore } from '$lib/store/language.store';
import { createCrossSellStores } from '$lib/store/crossSell.store';
import type { CartLine } from '$lib/types';
import { createSkuItemsFromCart, createSpecialSaleStore } from '$lib/store/specialSale.store';
import {
	NOTIFICATION,
	NOTIFICATION_DURATION,
	notificationStore
} from '$lib/store/notification.store';
import { type TFnType } from '@tolgee/svelte';
import { mapToNode } from '$lib/utils/product';
import { getCountry, getLanguage } from '$lib/utils/i18n';
import { addSDS, trimSDS } from '$lib/utils/sds';
import { tripleTrack } from '$lib/whale';
import { getVariantIdFromShopifyUri } from '$lib/utils';

type SDSPayload = {
	sku: string;
	handle: string;
	quantity: number;
	avantradoOnHand: number;
	sdsOnHand: number;
	variantId: string;
	sdsId: string;
	sdsThreshold: number;
};

type SDSChangeset = {
	remove: string;
	add?: {
		id: string;
		quantity: number;
	};
};

type ReplacementCheckUpResponse = {
	variant?: BaseSDSOnlyData$data;
	canReplace: boolean;
};

type SaleChangedResponse = 'no-change' | 'product' | 'code';

const createCartStore = () => {
	const readCartIdFromStorage = () => localStorage.getItem('cartId') ?? undefined;
	const saveCartIdToStorage = (cartId: string) => localStorage.setItem('cartId', cartId);
	const hasI18NData = () =>
		browser ? !!get(languageStore)?.locale && !!get(languageStore)?.country : false;
	let $t: TFnType;

	const cartId = writable<string | undefined>(undefined);
	const cartData = writable<RequiredCardData$data | undefined>(undefined);
	cartId.subscribe((value) => {
		if (!browser || get(cartData) || !hasI18NData()) {
			return;
		}
		if (value) {
			if (!get(cartData)) {
				const store = new CartStore();
				store
					.fetch({
						variables: {
							country: getCountry(),
							language: getLanguage(),
							id: value
						}
					})
					.then((response) => {
						if (response.data?.cart) {
							cartData.set(response.data.cart);
						} else {
							clear();
						}
					});
			}
		} else if (!readCartIdFromStorage()) {
			const store = new CartCreateStore();
			store
				.mutate({
					country: getCountry(),
					language: getLanguage()
				})
				.then((response) => {
					if (response.data?.cartCreate?.cart) {
						cartData.set(response.data.cartCreate.cart);
						cartId.set(response.data.cartCreate.cart.id);
						saveCartIdToStorage(response.data.cartCreate.cart.id);
					}
				});
		}
	});

	const isSDSEnabled = (cart: RequiredCardData$data | undefined) => {
		return mapToNode(cart?.lines).some((s) => s.merchandise.product.handle.endsWith('-sds'));
	};

	const identityData = derived([cartId, customerSessionStore], (stores) => stores);
	identityData.subscribe(([$cartId, $customerSessionStore]) => {
		if (!$cartId || !browser || !hasI18NData()) {
			return;
		}
		const store = new CartBuyerIdentityUpdateStore();
		store
			.mutate({
				cartId: $cartId,
				country: getCountry(),
				language: getLanguage(),
				buyerIdentity: {
					customerAccessToken: $customerSessionStore?.data?.accessToken ?? undefined,
					countryCode: getCountry()
				}
			})
			.then(({ data }) => {
				if (data?.cartBuyerIdentityUpdate?.cart) {
					cartData.set(data.cartBuyerIdentityUpdate.cart);
				}
			});
	});

	const applyDiscount = async (discountCode: string) => {
		const localCartId = get(cartId);
		if (!localCartId) {
			return;
		}
		const store = new CartDiscountCodesUpdateStore();
		const { data } = await store.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			discountCodes: discountCode.length > 0 ? [discountCode] : []
		});
		const applicableDiscounts = data?.cartDiscountCodesUpdate?.cart?.discountCodes?.filter(
			(s) => s.applicable
		);
		if (data?.cartDiscountCodesUpdate?.cart) {
			cartData.set(data.cartDiscountCodesUpdate.cart);
		}
		if ((applicableDiscounts?.length ?? 0) === 0) {
			const hasAlreadyDiscountedProducts = mapToNode(
				data?.cartDiscountCodesUpdate?.cart?.lines
			).some((line) =>
				line.discountAllocations.some((s) => (Number(s.discountedAmount.amount) || 0) > 0)
			);
			if (hasAlreadyDiscountedProducts) {
				return 'discountError.alreadyDiscountedProducts';
			}

			return 'discountError.invalidCode';
		} else if ((data?.cartDiscountCodesUpdate?.userErrors.length ?? 0) > 0) {
			return data?.cartDiscountCodesUpdate?.userErrors[0].message;
		}
	};

	const resetDiscounts = async () => {
		await applyDiscount('');
	};

	const changeQuantity = async ({
		sku,
		quantity,
		sdsThreshold,
		sdsOnHand,
		sdsId,
		variantId,
		avantradoOnHand
	}: SDSPayload) => {
		console.log('Changing quantity', sku, quantity, sdsThreshold);
		const lines = getLineItemIds(sku);
		const localCartId = get(cartId);
		if (!localCartId || lines.length === 0) {
			return;
		}

		const realLine = mergeCart(get(cartData)?.lines).find((line) => lines.includes(line.id))!;
		const isSDSLine = realLine.merchandise.product.handle.endsWith('-sds');

		const quantityIncreased = quantity > realLine.quantity;
		const quantityShrunken = quantity < realLine.quantity;
		const exceedsThreshold = quantity > sdsThreshold;
		const onlySDSCanSatisfy = avantradoOnHand < quantity && sdsOnHand >= quantity;

		if (!isSDSLine && quantityIncreased && (exceedsThreshold || onlySDSCanSatisfy)) {
			const changeSet: SDSChangeset[] = [
				{
					remove: lines[0],
					add: {
						id: sdsId,
						quantity: quantity
					}
				},
				...lines.slice(1).map((line) => ({
					remove: line
				}))
			];
			await _executeChangeSet(changeSet);
			await tryReplaceTo(true);
			return getLineItemIds(sku)[0];
		}
		if (
			isSDSLine &&
			quantityShrunken &&
			!exceedsThreshold &&
			!onlySDSCanSatisfy &&
			(await canUseAvantradoForEveryProduct(sku))
		) {
			const changeSet: SDSChangeset[] = [
				{
					remove: lines[0],
					add: {
						id: variantId,
						quantity: quantity
					}
				},
				...lines.slice(1).map((line) => ({
					remove: line
				}))
			];
			await _executeChangeSet(changeSet);
			await tryReplaceTo(false);
			return getLineItemIds(sku)[0];
		}

		const lineUpdates = lines.map((line) => ({
			id: line,
			quantity: 0
		}));
		lineUpdates[0].quantity = quantity;

		const store = new CartLinesUpdateStore();
		const { data } = await store.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			lines: lineUpdates
		});
		if (data?.cartLinesUpdate?.cart) {
			cartData.set(data.cartLinesUpdate.cart);
		}
		return lines[0];
	};

	const getLineItemIds = (sku: string) => {
		const cart = get(cartData);
		return mapToNode(cart?.lines)
			.filter((line) => line.merchandise.sku?.value === sku)
			.map((line) => line.id);
	};

	const getReplacementData = async (
		line: CartLine,
		convertToSDS: boolean
	): Promise<ReplacementCheckUpResponse> => {
		const store = new GetSDSOnlyInfoStore();

		const { data } = await store.fetch({
			variables: {
				country: getCountry(),
				language: getLanguage(),
				sdsHandle: addSDS(line.merchandise.product.handle),
				handle: trimSDS(line.merchandise.product.handle)
			}
		});

		const otherVariant = mapToNode(
			convertToSDS ? data?.sdsProduct?.variants : data?.product?.variants
		).find((item) => item.sku?.value === line.merchandise.sku?.value);
		if (!otherVariant || (otherVariant.quantityAvailable ?? 0) < line.quantity) {
			console.warn(`Could not find replacement for ${line.merchandise.product.handle}`);
			return {
				variant: otherVariant,
				canReplace: false
			};
		}

		return {
			variant: otherVariant,
			canReplace: true
		};
	};

	const canUseAvantradoForEveryProduct = async (...ignoreSKUs: string[]) => {
		const cartLines = mergeCart(get(cartData)?.lines);
		const hasProductWithThreshold = cartLines.some(
			(line) => line.quantity > (Number(line.merchandise.sds_qty?.value || '10') || 10)
		);
		if (hasProductWithThreshold) {
			return false;
		}

		const sdsLines = cartLines.filter((line) => {
			return (
				line.merchandise.product.handle.endsWith('-sds') &&
				!ignoreSKUs.includes(line.merchandise.sku?.value || '')
			);
		});
		return await Promise.all(
			sdsLines.map(async (line) => {
				const { canReplace } = await getReplacementData(line, false);

				return canReplace;
			})
		).then((d) => d.every((s) => s));
	};

	const _executeChangeSet = async (changeSet: SDSChangeset[]) => {
		if (changeSet.length === 0) {
			return;
		}
		const removeSet = changeSet.map((s) => s.remove);
		const addSet = changeSet
			.map((s) =>
				s.add
					? {
							merchandiseId: s.add.id,
							quantity: s.add.quantity
						}
					: null
			)
			.filter((s) => !!s) as Array<{ merchandiseId: string; quantity: number }>;

		const localCartId = get(cartId);
		if (!localCartId) {
			return;
		}

		const removeStore = new CartLinesRemoveStore();
		await removeStore.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			lineIds: removeSet
		});

		const addStore = new CartLinesAddStore();
		const { data } = await addStore.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			lines: addSet
		});

		if (data?.cartLinesAdd?.cart) {
			cartData.set(data.cartLinesAdd.cart);
		}
	};

	const tryReplaceTo = async (convertToSDS: boolean) => {
		const cartLines = mergeCart(get(cartData)?.lines);
		const avantradoLines = cartLines.filter((line) => {
			const isSDSProduct = line.merchandise.product.handle.endsWith('-sds');
			return isSDSProduct !== convertToSDS;
		});
		const infoDataForLines = await Promise.all(
			avantradoLines.map(async (line) => {
				const { variant, canReplace } = await getReplacementData(line, convertToSDS);

				if (!canReplace || !variant) {
					return null;
				}

				return {
					remove: line.id,
					add: {
						id: variant.id,
						quantity: line.quantity
					}
				};
			})
		).then((d) => d.filter((s) => !!s) as SDSChangeset[]);
		await _executeChangeSet(infoDataForLines);
	};

	const checkSaleChangedForSnapshots = (snapshot: RequiredCardData$data): SaleChangedResponse => {
		const pastApplicable = snapshot?.discountCodes.filter((s) => s.applicable) || [];
		const nowApplicable = get(cartData)?.discountCodes.filter((s) => s.applicable) || [];

		// Unnecessary optimization, but it's a bit faster and I find it cool :D
		switch (nowApplicable.length - pastApplicable.length) {
			case 0:
				return 'no-change';
			case 1:
				return 'code';
			default:
				return 'product';
		}
	};

	const warnIfSaleChangedForSnapshots = (snapshot: RequiredCardData$data | undefined) => {
		switch (snapshot && checkSaleChangedForSnapshots(snapshot)) {
			case 'code':
				notificationStore.addNotification({
					actions: [{ text: $t('notification.close') }],
					hideAfter: NOTIFICATION_DURATION.LONG,
					text: $t('discountError.discountedCodeHasReplacedProduct'),
					title: $t('cart.discountCode.error.title'),
					variant: NOTIFICATION.SUCCESS
				});
				break;
			case 'product':
				notificationStore.addNotification({
					actions: [{ text: $t('notification.close') }],
					hideAfter: NOTIFICATION_DURATION.LONG,
					text: $t('discountError.discountedProductHasReplacedOriginalDiscount'),
					title: $t('cart.discountCode.error.title'),
					variant: NOTIFICATION.ALERT
				});
				break;
		}
	};

	/**
	 * Adds an item to the cart. If the item already exists, it will update the quantity.
	 *
	 * To maintain stable SDS Support this function takes a bunch of args to determine if the item should be added as SDS or Avantrado.
	 * Most of the data can be acquired using an GetSDSOnlyInfo query store ore using the ProductCardMissingInfoStore.
	 *
	 * @param sku The SKU of the item to add (cleaned from _sds and so on use "just_sku" metafield)
	 * @param quantity The quantity to add
	 * @param variantId The Avantrado Variant Id
	 * @param sdsId The Sprenger Own Shipment Center Variant Id
	 * @param sdsThreshold When to use the sdsId instead of the variantId in case quantity exceeds this number
	 * @param handle The handle of the product
	 * @param sdsOnHand The amount of SDS items on hand
	 * @param avantradoOnHand The amount of Avantrado items on hand
	 */
	const addSDSItem = async ({
		sku,
		quantity,
		variantId,
		sdsId,
		sdsThreshold,
		handle,
		sdsOnHand,
		avantradoOnHand
	}: SDSPayload) => {
		console.log('Adding item', sku, quantity, variantId, sdsId, sdsThreshold, handle);
		const currentLines = getLineItemIds(sku);
		if (currentLines.length > 0) {
			console.warn('Tried adding item, but it already exists in cart', currentLines);
			return changeQuantity({
				sku,
				quantity: getQuantity(sku) + quantity,
				sdsThreshold,
				handle,
				variantId,
				sdsId,
				avantradoOnHand,
				sdsOnHand
			});
		}

		const localCartId = get(cartId);
		if (!localCartId) {
			return;
		}

		const hasReachedThreshold = quantity >= sdsThreshold;
		const cartSnapshot = get(cartData);
		const isSDS = isSDSEnabled(cartSnapshot);
		const onlySDSCanSatisfy = avantradoOnHand < quantity && sdsOnHand >= quantity;
		if (isSDS || hasReachedThreshold || onlySDSCanSatisfy) {
			if (sdsOnHand >= quantity) {
				const store = new CartLinesAddStore();
				const { data } = await store.mutate({
					cartId: localCartId,
					country: getCountry(),
					language: getLanguage(),
					lines: [
						{
							quantity: quantity,
							merchandiseId: sdsId
						}
					]
				});
				if (data?.cartLinesAdd?.cart) {
					tripleTrack('AddToCart', { item: getVariantIdFromShopifyUri(sdsId), q: quantity });
					cartData.set(data.cartLinesAdd.cart);

					await tryReplaceTo(true);

					warnIfSaleChangedForSnapshots(cartSnapshot);

					return getLineItemIds(sku)[0];
				}
				return;
			}
		}

		const store = new CartLinesAddStore();
		const { data } = await store.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			lines: [
				{
					quantity: quantity,
					merchandiseId: variantId
				}
			]
		});

		if (data?.cartLinesAdd?.cart) {
			tripleTrack('AddToCart', { item: getVariantIdFromShopifyUri(variantId), q: quantity });
			cartData.set(data.cartLinesAdd.cart);

			warnIfSaleChangedForSnapshots(cartSnapshot);

			return getLineItemIds(sku)[0];
		}
	};

	const removeItem = async (sku: string) => {
		console.log('Removing item', sku);
		const lines = getLineItemIds(sku);
		const localCartId = get(cartId);
		if (!localCartId) {
			return;
		}

		const snapshot = get(cartData);

		const store = new CartLinesRemoveStore();
		const { data } = await store.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			lineIds: lines
		});
		if (data?.cartLinesRemove?.cart) {
			cartData.set(data.cartLinesRemove.cart);

			warnIfSaleChangedForSnapshots(snapshot);

			if (await canUseAvantradoForEveryProduct()) {
				await tryReplaceTo(false);
			}
		}
	};

	const removeSpecificLineItem = async (lineId: string) => {
		const localCartId = get(cartId);
		if (!localCartId) {
			return;
		}

		const snapshot = get(cartData);

		const store = new CartLinesRemoveStore();
		const { data } = await store.mutate({
			cartId: localCartId,
			country: getCountry(),
			language: getLanguage(),
			lineIds: [lineId]
		});
		if (data?.cartLinesRemove?.cart) {
			cartData.set(data.cartLinesRemove.cart);

			warnIfSaleChangedForSnapshots(snapshot);

			if (await canUseAvantradoForEveryProduct()) {
				await tryReplaceTo(false);
			}
		}
	};

	const getQuantity = (sku: string) => {
		const cart = get(cartData);
		return mapToNode(cart?.lines)
			.filter((line) => line.merchandise.sku?.value === sku)
			.reduce((a, b) => a + b.quantity, 0);
	};

	const getRealQuantity = (sku: string, quantityAvailable: number) => {
		return quantityAvailable - getQuantity(sku);
	};

	const init = (t: TFnType) => {
		cartId.set(readCartIdFromStorage() || '');
		$t = t;
	};

	const clear = () => {
		localStorage.removeItem('cartId');
		cartData.set(undefined);
		cartId.set(undefined);
	};

	const hasSameAllocations = (
		first: CartLine['discountAllocations'],
		second: CartLine['discountAllocations']
	) => {
		const firstItem = first.filter((s) => s.discountedAmount.amount > 0);
		const secondItem = second.filter((s) => s.discountedAmount.amount > 0);
		if (firstItem.length !== secondItem.length) {
			return false;
		}
		for (const discountOption of firstItem) {
			if (
				!secondItem.some(
					(d) => d.discountedAmount.amount === discountOption.discountedAmount.amount
				)
			) {
				return false;
			}
		}
		return true;
	};

	const mergeCart = (lineData: RequiredCardData$data['lines'] | undefined) => {
		const mapped = mapToNode(lineData).map((line) => {
			const productPriceCalculated = line.merchandise.price.amount * line.quantity;
			const totalCostAmount = line.cost.totalAmount.amount;

			// I think this is the right balance between trusting shopify with the price and absurd discounts
			if (totalCostAmount / productPriceCalculated < 0.6) {
				return {
					...line,
					cost: {
						...line.cost,
						totalAmount: {
							...line.cost.totalAmount,
							amount: productPriceCalculated
						}
					}
				};
			}

			return line;
		});
		const result: typeof mapped = [];
		for (const line of mapped) {
			const lineIndex = result.findIndex(
				(l) => l.merchandise.sku?.value === line.merchandise.sku?.value
			);
			if (lineIndex === -1) {
				result.push(line);
			} else {
				if (hasSameAllocations(line.discountAllocations, result[lineIndex].discountAllocations)) {
					result[lineIndex] = {
						...result[lineIndex],
						quantity: result[lineIndex].quantity + line.quantity,
						cost: {
							...result[lineIndex].cost,
							totalAmount: {
								...result[lineIndex].cost.totalAmount,
								amount: result[lineIndex].cost.totalAmount.amount + line.cost.totalAmount.amount
							},
							subtotalAmount: {
								...result[lineIndex].cost.subtotalAmount,
								amount:
									result[lineIndex].cost.subtotalAmount.amount + line.cost.subtotalAmount.amount
							}
						}
					};
				} else {
					result.push(line);
				}
			}
		}
		return result;
	};

	const reactive = derived([cartData], ([$cartData]) => {
		const mergedLines = mergeCart($cartData?.lines);
		const mappedLines = mapToNode($cartData?.lines);

		return {
			data: $cartData,
			requiresSDS: isSDSEnabled($cartData),
			lines: mergedLines,
			totalQuantity: mappedLines?.reduce((a, b) => a + b.quantity, 0) ?? 0,
			getQuantity: getQuantity,
			crossSellProducts: createCrossSellStores(
				...mappedLines
					.map((line) => [
						line.merchandise.product.crossSellReferences,
						line.merchandise.crossSellReferences
					])
					.flat()
			),
			specialSale: createSpecialSaleStore(...createSkuItemsFromCart(mergedLines)),
			getRealQuantity
		};
	});

	return {
		...reactive,
		addSDSItem,
		removeItem,
		changeQuantity,
		init,
		applyDiscount,
		resetDiscounts,
		clear,
		removeSpecificLineItem
	};
};

export const cart = createCartStore();
