import { type ProductCardMissingInfo$result, ProductCardMissingInfoStore } from '$houdini';
import { languageStore } from './language.store';
import { get, type Readable, readonly, writable, type Writable } from 'svelte/store';
import { browser } from '$app/environment';
import { deduplicate, isNetworkError, type MetaField } from '$lib/utils';
import { addSDS, trimSDS } from '$lib/utils/sds';
import { ensureCountry, ensureLocale } from '$lib/utils/i18n';
import { mapToNode } from '$lib/utils/product';

type CrossSellProductData = {
	parentHandle: string;
	selectedOptions?: {
		name: string;
		value: string;
	}[];
};

export type CrossSellStoreCreationResult = Readable<
	{
		data: {
			product: ProductCardMissingInfo$result | null;
			sku: string;
		} | null;
	}[]
>;

export const createCrossSellStores = (...data: (MetaField | undefined | null)[]) => {
	const fetchCrossSellProducts = (data: CrossSellProductData[]) => {
		const toRequest = deduplicate(data, (f, s) => f?.parentHandle === s?.parentHandle).filter(
			(s) => !!s
		);
		const result: Writable<
			{
				data: {
					product: ProductCardMissingInfo$result | null;
					sku: string;
				} | null;
			}[]
		> = writable(toRequest.map(() => ({ data: null })));
		if (!browser) {
			return readonly(result);
		}

		let i = 0;
		for (const item of toRequest) {
			const store = new ProductCardMissingInfoStore();
			const localI = Number(i);
			store
				.fetch({
					variables: {
						handle: trimSDS(item.parentHandle),
						sdsHandle: addSDS(item.parentHandle),
						language: ensureLocale(get(languageStore).locale),
						country: ensureCountry(get(languageStore).country)
					}
				})
				.then(({ data }) => {
					if (data?.product) {
						const sku = item.selectedOptions?.length
							? mapToNode(data?.product?.variants).find((variant) =>
									variant?.selectedOptions?.every((option) =>
										item.selectedOptions?.some(
											(selectedOption) =>
												selectedOption.name === option.name && selectedOption.value === option.value
										)
									)
								)?.sku
							: data?.product?.variants.edges[0].node.sku;

						result.update((current) => {
							const changeset = [...current];
							changeset[localI] = {
								data: {
									product: data,
									sku: sku?.value || ''
								}
							};
							return changeset;
						});
					}
				})
				.catch((e) => {
					if (isNetworkError(e)) {
						return;
					}
					throw e;
				});

			i++;
		}

		return readonly(result);
	};

	return fetchCrossSellProducts(
		data.map((item) => JSON.parse(item?.value || '[]') as CrossSellProductData[]).flat()
	);
};

/*            const sku = item.selectedOptions?.length
                ? mapToNode(data?.product?.variants).find((variant) =>
                    variant?.selectedOptions?.every((option) =>
                        item.selectedOptions?.some(
                            (selectedOption) =>
                                selectedOption.name === option.name && selectedOption.value === option.value
                        )
                    )
                )?.sku
                : data?.product?.variants.edges[0].node.sku;
*/
